import React from 'react';
import Header from "../../../../Components/Header/Header";

const Counter = (props) => {
    return (
        <div>
            <Header fontSize="text-xxl mb-[20%]">
                {props.wasAnswered ? 'EREDMÉNY' : props.questionCount + '/' + props.maxQuizNumber}
            </Header>
        </div>
    );
};

export default Counter;