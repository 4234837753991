import React from 'react';

const Rfid = (props) => {
    return (
        <img src="/assets/touch-icon.png" className="absolute" style={{
            width: "5.88vw",
            top: props.top,
            left: props.left,
            right: props.right,
            bottom: props.bottom,
            zIndex: props.zIndex
        }}/>
    );
};

export default Rfid;