import React, {useEffect, useState} from 'react';
import './Box.scss'

const Box = (props) => {
    const [isSelected, setIsSelected] = useState(false);
    const [isShowing, setIsShowing] = useState(false);
    const boxclasses = "relative flex flex-col items-center";
    const boxTop = !isSelected ? isShowing ? 'preview' : 'closed' : 'open';
    const visible = isSelected && !isShowing && 'light';
    const animate = isSelected ? ' animate' : isShowing && 'preview';


    useEffect(() => {
        setTimeout(() => {
            if (props.boxId === props.winnerId) {
                setIsShowing(true);
                setTimeout(() => {
                    setIsShowing(false);

                }, 5000)
            }
        }, 1000)

    }, [])


    return (

        <>
            <div className={'flex justify-around box move-box-' + props.boxId} id={props.boxId}>
                <div className={boxclasses} onClick={() => {
                    setIsSelected(!isSelected);
                    props.setSelectedId(props.boxId);
                    console.log(props.boxId);
                }}>
                    <img src="/assets/box-top.png" alt="" className={'box-top ' + boxTop}/>
                    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="1098"
                         height="980" viewBox="0 0 1098 951"
                         className={'bottom-0 absolute z-0 opacity-0 transition-all duration-1000 ' + visible}>
                        <defs>
                            <linearGradient id="linear-gradient" x1="0.5" x2="0.5" y2="1"
                                            gradientUnits="objectBoundingBox">
                                <stop offset="0" stop-color="#fff"/>
                                <stop offset="1" stop-color="#fff" stop-opacity="0"/>
                            </linearGradient>
                        </defs>
                        <g id="Polygon_5" data-name="Polygon 5" transform="translate(1098 951) rotate(180)"
                           fill="url(#linear-gradient)">
                            <path
                                d="M 1097.133911132812 950.5 L 0.8660319447517395 950.5 L 549 1.000175595283508 L 1097.133911132812 950.5 Z"
                                stroke="none"/>
                        </g>
                    </svg>
                    <img src="/assets/box-bottom.png" alt="" className={'max-w-[340px] z-10'}/>

                    {props.boxId === props.winnerId && <img src="/assets/Smiley.png" alt=""
                                                            className={'z-0 absolute bottom-0 smiley ' + animate}/>}
                </div>

            </div>
        </>
    );
};

export default Box;
