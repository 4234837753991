import React from 'react';
import Triangles from "../../../../Components/Shapes/Triangles";
import VerticalLines from "../../../../Components/Shapes/VerticalLines";
import HorizontalLines from "../../../../Components/Shapes/HorizontalLines";
import Circles from "../../../../Components/Shapes/Circles";
import styles from "../../Quiz/Screens/QuizGame.module.scss";

const Cover = (props) => {
    return (
        <div className="basis-1/2 relative">
            <img src="/assets/Games/Introduction/jm-team.png" alt="" className={props.animate ==="true" ? styles["fadeIn"] + " w-[90%]" : "w-[90%]"}/>
            <Triangles top={'-20%'} left={'-5%'}/>
            <VerticalLines top={'-20%'} right={'15%'}/>
            <HorizontalLines bottom={'10%'} left={'-5%'}/>
            <Circles bottom={'-10%'} right={'25%'}/>
        </div>
    );
};

export default Cover;