import React from 'react';
import classes from "./Backdrop.module.scss";

const Backdrop = (props) => {
    return (
        <div className="bg-gray relative min-h-screen lg:overflow-hidden">
            <div className={classes["backdrop__shape"]  + " z-0 bg-dark-gray absolute"}></div>
            <div className="bg-brand fixed top-0 bottom-0 right-0 w-[1%]"></div>
            <div className="z-10 relative">
                {props.children}
            </div>
        </div>
    );
};

export default Backdrop;