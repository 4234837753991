export const text = [
    {
        id: 'A',
        screen: 'branding',
        title: 'KREATÍV TERVEZÉS',
        paragraph: [
            'A folyamat a kreatív tervezéssel kezdődik legyen szó klasszikus nyomtatott kommunikációs médiumról, interaktív alkalmazásról, vagy mozgóképről.',
            'Kitaláljuk, leskicceljük, vázlatokat, drótvázat vagy akár működő UX prototípust készítünk. Megtervezzük az arculatot, megrajzoljuk és animáljuk a grafikákat, és kompletten legyártjuk a nyomdaipari termékeket is.'
        ],
        list: [
            'Branding',
            'Nyomtatott kommunikáció',
            'Digitális kommunikáció',
            'Mozgókép',
            'UX design',
        ]
    },
    {
        id: 'B',
        screen: 'development',
        title: 'SZOFTVERFEJLESZTÉS',
        paragraph: [
            'A számítógépes alkalmazások széles palettáját ismerjük. Fejlesztünk weboldalakat különböző platformokra, mobil alkalmazásokat, játékokat, interaktív edukációs eszközöket.',
            'WordPress vagy Drupal alapú oldalak, Veeva e-detailer prezentációk és Approved e-mail kampányok, standaktivitás és kiállítási játékok, valamint mobil alkalmazások egyaránt megtalálhatók a kínálatunkban.'
        ],
        list: [
            'Weboldal',
            'E-detailer',
            'Email marketing',
            'Standaktivitás',
            'Mobil alkalmazás'
        ]
    },
    {
        id: 'C',
        screen: 'onlineMarketing',
        title: 'ONLINE MARKETING',
        paragraph: [
            'Ha elkészült a weboldal, akkor kezdődik csak az igazi munka, hiszen a keresőoptimalizálás és a hirdetések segítségével indul a látogatók vásárlóvá konvertálása.',
            'Az elkészített weboldalakat beállítjuk a legfontosabb SEO standardoknak megfelelően, és elkészítjük a Google Ads kereső, display vagy videó kampányokat is. Dőlj hátra és bízd a kampányaidat szakértőkre, ránk!'
        ],
        list: [
            'Kereső optimalizálás (SEO)',
            'Google Ads hirdetések (SEA)'
        ]
    }
]