import React, {useState} from 'react';
import QuizGame from "./Screens/QuizGame";
import {questions} from "./Config/QuizConfig";


const Quiz = (props) => {

    const quizQuestions = [...questions];
    const [questionCount, setQuestionCount] = useState(1);
    const [correctAnswerCounter, setCorrectAnswerCounter] = useState(0);
    const [isCorrectModal, setIsCorrectModal] = useState(false);

    return (
        <div className={"text-brand text-md"}>
            <div className="relative z-10">
                <QuizGame setActiveView={props.setActiveView}
                          isCorrectModal={isCorrectModal} setIsCorrectModal={setIsCorrectModal}
                          correctAnswerCounter={correctAnswerCounter}
                          setCorrectAnswerCounter={setCorrectAnswerCounter} quizQuestions={quizQuestions}
                          setQuestionCount={setQuestionCount} questionCount={questionCount}
                />
            </div>
        </div>
    );
}

export default Quiz;
