import './App.scss';
import {useState} from "react";
import Start from "./Containers/Start/Start";
import Games from "./Containers/Games/Games";
import Exit from "./Containers/Exit/Exit";
import Quiz from "./Containers/Games/Quiz/Quiz";
import Gambling from "./Containers/Games/Gambling/Gambling";
import Introduction from "./Containers/Games/Introduction/Introduction";
import Backdrop from "./Components/Backdrop/Backdrop";
import Logo from "./Components/Logo/Logo";
import {useIdleTimer} from "react-idle-timer";

const App = () => {


    const [activeView, setActiveView] = useState('start');

    const views = {
        'start': <Start setActiveView={setActiveView}/>,
        'games': <Games setActiveView={setActiveView}/>,
        'exit': <Exit setActiveView={setActiveView}/>,
        'quiz': <Quiz setActiveView={setActiveView}/>,
        'gambling': <Gambling setActiveView={setActiveView}/>,
        'introduction': <Introduction setActiveView={setActiveView}/>
    }

    const handleOnIdle = () => {
        setActiveView("start");
    }

    const idleTimer = useIdleTimer({
        onIdle: handleOnIdle,
        timeout: 1000 * 60 * 5
    })

    return (
        <Backdrop>
            {views[activeView]}
            <Logo/>
        </Backdrop>
    );
}

export default App;
