import React from 'react';
import Card from "../../../../Components/Card/Card";
import Header from "../../../../Components/Header/Header";
import {text} from "../Config/introductionConfig";
import FlipCard from "../../../../Components/FlipCard/FlipCard";

const Text = (props) => {

    let selectedScreen = text.filter(item => item.screen === props.activeScreen).pop()
    let otherScreens = text.filter(item => item.screen !== props.activeScreen)

    return (
        <div className="basis-1/2 h-full">
            <Card classes="py-[2%] px-[8%] h-[85vh]">
                <div className="h-[100%] flex flex-col">
                    <Header>JACSOMÉDIA DIGITÁLIS ÜGYNÖKSÉG</Header>
                    <p className="text-md text-primary mb-[2%] mt-[4%] font-bold">{selectedScreen.title}</p>
                    {
                        selectedScreen.paragraph.map((item, index) => (
                            <p key={index} className="text-primary py-3 flex items-center text-base">
                                {item}
                            </p>
                        ))
                    }
                    <ul className="text-primary py-2 text-[85%]">
                        {
                            selectedScreen.list.map((item, index) => (
                                <li key={index} className="flex items-center py-1">
                                <span
                                    className="rounded-full mr-5 bg-[#FECD00] w-6 h-6 aspect-square inline-block">&nbsp;</span>
                                    {item}
                                </li>
                            ))
                        }
                    </ul>
                    <div className="flex items-baseline gap-[2%] mt-auto">
                        <p className="text-primary py-2 pb-2 flex items-center text-base mt-1">Ez is érdekelhet:</p>
                        <ul className="text-primary grow">
                            {
                                otherScreens.map(item => (
                                    <li key={item.id} className="py-2 flex items-center text-base">
                                <span
                                    onClick={() => props.setActiveScreen(item.screen)}
                                    className="border border-solid text-[#898EA7] mr-3 bg-[#363948] rounded-full w-[8%] aspect-square flex justify-center items-center">
                                    {item.id}
                                </span>
                                        {item.title}
                                    </li>
                                ))
                            }
                        </ul>
                    </div>
                    <p onClick={() => props.setActiveScreen('game')}
                       className="font-bold py-1 flex items-center text-base text-brand relative mt-auto">
                        www.jacsomedia.hu
                    </p>
                </div>
            </Card>
        </div>
    );
};

export default Text;
