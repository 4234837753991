import React, {useEffect, useState} from 'react';
import Box from "./Components/Box";
import Card from "../../../Components/Card/Card";
import Header from "../../../Components/Header/Header";
import Triangles from "../../../Components/Shapes/Triangles";
import Circles from "../../../Components/Shapes/Circles";
import VerticalLines from "../../../Components/Shapes/VerticalLines";
import Rfid from "../../../Components/Shapes/Rfid";


const Gambling = (props) => {
    const boxes = ['A', 'B', 'C'];
    const [winner, setWinner] = useState([...boxes].sort(() => Math.random() - 0.5)[0]);
    const [selectedId, setSelectedId] = useState('');
    const [gameResult, setGameResult] = useState(true);

    useEffect(() => {
        setTimeout(() => {
            setWinner([...boxes].sort(() => Math.random() - 0.5)[1]);
        }, 10000)
    }, [])

    useEffect(() => {
        Array.from(document.querySelectorAll('.fail-animation')).forEach((el) => el.classList.remove('fail-animation')); //ha szerepel rajta a class, akkor eltávolítja
        if (selectedId !== '') {
            if (selectedId !== winner) {
                setGameResult(false);
                setTimeout(() => {
                    document.getElementById(winner).classList.add('fail-animation');
                }, 3000)
            }
            setTimeout(() => {
                props.setActiveView('exit')
            }, 10000)
        }

    }, [selectedId])

    const getBoxes = () => {
        return boxes.map(box => <Box winnerId={winner}
                                     boxId={box}
                                     setActiveView={props.setActiveView}
                                     setSelectedId={setSelectedId}
                                     socket={props.socket}
                                     tagIds={props.tagIds}
                                />)
    }

    return (
        <div className={"text-brand text-md"}>
            <div className="flex items-center justify-center min-h-screen">
                <Card classes="py-[4%] px-[4%] w-[93%]">
                    <Header
                        fontSize="text-lg">{selectedId !== '' ? (gameResult ? 'Gratulálunk!' : 'Sajnos most nem sikerült!') : 'Válassz egy dobozt!'}</Header>
                    <div className="flex justify-around my-[5%]">{getBoxes()}</div>
                    <div className="flex flex-row justify-around mt-16 mb-6">
                        <Header fontSize="text-xl">A</Header>
                        <Header fontSize="text-xl">B</Header>
                        <Header fontSize="text-xl">C</Header></div>
                </Card>
            </div>
            <Rfid left={'200px'} bottom={'240px'}/>
            <Circles top={'216px'} right={'47px'}/>
            <VerticalLines top={'20px'} right={'280px'} zIndex={'-5'}/>
            <Triangles top={'30px'} left={'30px'}/>
        </div>
    );
}

export default Gambling;