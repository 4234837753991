import React from 'react';
import styles from "./TouchIcon.module.scss";

const TouchIcon = (props) => {
    return (
        <div className={"relative w-2/5 mx-auto my-[8%] z-10 " + styles['pulse']}>
            <img src="/assets/touch-icon.png" alt="Start" className="relative z-40" onClick={()=>props.setActiveView('games')}/>
            <div className={styles["pulse1"] + " " +styles['pulse__shadow'] + " absolute w-[55%] h-full top-0 rounded-full z-10 ml-[0.5%]"}></div>
        </div>
    );
}

export default TouchIcon;