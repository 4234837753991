import React, {useState} from 'react';
import IntroductionGame from "./Screens/IntroductionGame";
import Branding from "./Screens/Branding";
import Development from "./Screens/Development";
import '../../../scss/global.scss'
import OnlineMarketing from "./Screens/OnlineMarketing";

const Introduction = (props) => {

    const [activeScreen, setActiveScreen] = useState('game');

    const screens = {
        'game': <IntroductionGame setActiveScreen={setActiveScreen}
                                  setActiveView={props.setActiveView}
        />,
        'branding': <Branding setActiveScreen={setActiveScreen}
                              activeScreen={activeScreen}
        />,
        'development': <Development setActiveScreen={setActiveScreen}
                                    activeScreen={activeScreen}
                                     />,
        'onlineMarketing': <OnlineMarketing setActiveScreen={setActiveScreen}
                                            activeScreen={activeScreen}
                                             />,
    }

    return (
        <div className={"text-brand text-md flex h-[100vh] px-[4%] items-center"}>
            {screens[activeScreen]}
        </div>
    );
}

export default Introduction;
