import React from 'react';
import classes from "./Circles.module.scss";

const Circles = (props) => {
    return (
        <div src="/assets/circles.png" className="absolute" style={{
            width: "8vw",
            height: "14vh",
            top: props.top,
            left: props.left,
            right: props.right,
            bottom: props.bottom,
            zIndex: props.zIndex
        }}>
            <div className="relative w-full h-full">
                <svg xmlns="http://www.w3.org/2000/svg" width="120" height="226" viewBox="0 0 226 226"
                     className={classes['circle-top'] + " absolute top-0-left-0"}>
                    <g id="Ellipse_3" data-name="Ellipse 3" fill="none" stroke="#fecd00" strokeWidth="6">
                        <circle cx="113" cy="113" r="113" stroke="none"/>
                        <circle cx="113" cy="113" r="110" fill="none"/>
                    </g>
                </svg>
                <svg xmlns="http://www.w3.org/2000/svg" width="200" height="251" viewBox="0 0 251 251"
                     className={classes['circle-bottom'] + " absolute bottom-0 right-0"}>
                    <g id="Ellipse_2" data-name="Ellipse 2" fill="none" stroke="#fecd00" strokeWidth="6">
                        <circle cx="125.5" cy="125.5" r="125.5" stroke="none"/>
                        <circle cx="125.5" cy="125.5" r="122.5" fill="none"/>
                    </g>
                </svg>
            </div>
        </div>
    );
};

export default Circles;
