import React from 'react';
import styles from "./FlipCard.module.scss";

const FlipCard = (props) => {
    return (
        <div className={styles['flip-card'] + " " + props.classes}>
            <div className={styles["flip-card-inner"] + " relative w-full h-full"}>
                <div className={styles["flip-card-front"] + " absolute w-full h-full"} onClick={props.onClick}>
                    {props.children}
                </div>
                <div
                    className={styles["flip-card-back"] + " absolute -z-10 w-full h-full rounded-[3%] bg-card-back"}></div>
            </div>
        </div>
    );
};

export default FlipCard;
