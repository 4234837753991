export const maxQuizNumber = 5;

export const gameTimerSeconds = 20;

export const resultTimerMillisecond = 20000;

export const questions = [{
    id: 1,
    question: 'Milyen magas a Mont Blanc?',
    answersId: [1, 2, 3],
    correctAnswerId: 2,
    image: "assets/QuizGame/question1Img.png",
    answerImage: "assets/QuizGame/question1AnswerImg.png",
    resultMessage: "A Mont Blanc magassága: 4809 m"
}, {
    id: 2,
    question: 'Hol található Nagyharsány?',
    answersId: [4, 5, 6],
    correctAnswerId: 5,
    image: "assets/QuizGame/question2Img.png",
    answerImage: "assets/QuizGame/question2AnswerImg.png",
    resultMessage: "Nagyharsány Baranya megyében található."
}, {
    id: 3,
    question: 'Honnan származik a Szamojéd?',
    answersId: [7, 8, 9],
    correctAnswerId: 9,
    image: "assets/QuizGame/question3Img.png",
    answerImage: "assets/QuizGame/question3AnswerImg.png",
    resultMessage: "A Szamojéd Oroszországból származik."
}, {
    id: 4,
    question: 'Ki tiltotta be a Karácsonyt?',
    answersId: [10, 11, 12],
    correctAnswerId: 10,
    image: "assets/QuizGame/question4Img.png",
    answerImage: "assets/QuizGame/question4AnswerImg.png",
    resultMessage: "A karácsonyt Oliver Cromwell tiltotta be."
}, {
    id: 5,
    question: 'A tollaskígyó melyik mitológia istene?',
    answersId: [13, 14, 15],
    correctAnswerId: 15,
    image: "assets/QuizGame/question5Img.png",
    answerImage: "assets/QuizGame/question5AnswerImg.png",
    resultMessage: "A tollaskígyó az azték mitológiák istene."
}]

export const answers = [{
    id: 1,
    answer: '4908 m'
}, {
    id: 2,
    answer: '4809 m'
}, {
    id: 3,
    answer: '4890 m'
}, {
    id: 4,
    answer: 'Tolna megye'
}, {
    id: 5,
    answer: 'Baranya megye'
}, {
    id: 6,
    answer: 'Csongrád megye'
}, {
    id: 7,
    answer: 'Belgium'
}, {
    id: 8,
    answer: 'Németország'
}, {
    id: 9,
    answer: 'Oroszország'
}, {
    id: 10,
    answer: 'Oliver Cromwell'
}, {
    id: 11,
    answer: 'Szép Fülöp'
}, {
    id: 12,
    answer: 'Luxemburgi Zsigmond'
}, {
    id: 13,
    answer: 'Indiai'
}, {
    id: 14,
    answer: 'Germán'
}, {
    id: 15,
    answer: 'Azték'
}]