import React, {useState} from 'react';
import Header from "../../Components/Header/Header";
import Card from "../../Components/Card/Card";
import TouchIcon from "../../Components/TouchIcon/TouchIcon";
import FlipCard from "../../Components/FlipCard/FlipCard";

const Games = (props) => {


    return (
        <div className="p-[4%]">
            <Header fontSize="text-xl">Válassz Az alábbi lehetőségek közül! </Header>
            <p className="text-base text-secondary text-center mt-[1%] mb-[3%]">A játék megkezdéséhez érintsd az A, B
                vagy C jelű kártyát a leolvasó terminálhoz, vagy lépj vissza a Start kártya segítségével.</p>
            <div className={"flex flex-row gap-[5%]"}>
                <FlipCard classes="basis-1/3 h-[52vh]" onClick={() => props.setActiveView('quiz')}>
                    <Card classes="py-[3%] px-[8%]">
                        <Header fontSize="text-xxl">A</Header>
                        <p className="text-center text-md text-primary mt-[15%] mb-[5%]">KVÍZJÁTÉK</p>
                        <p className="text-center text-base text-secondary">Ebben a játékban az NFC kártyáid
                            segítségével kérdésekre kell válaszolnod.</p>
                        <img src="/assets/h-lines.png" alt="" className="w-2/5 mx-auto my-[10%]"/>
                    </Card>
                </FlipCard>
                <FlipCard classes="basis-1/3 h-[52vh]" onClick={() => props.setActiveView('gambling')}>
                    <Card classes="py-[3%] px-[8%]">
                        <Header fontSize="text-xxl">B</Header>
                        <p className="text-center text-md text-primary mt-[15%] mb-[5%]">SZERENCSEJÁTÉK</p>
                        <p className="text-center text-base text-secondary">Ebben a játékban az NFC kártyáiddal itt a
                            piros, hol a piros játékot játszhatsz.</p>
                        <img src="/assets/triangles.png" alt="" className="w-2/5 mx-auto my-[10%]"/>
                    </Card>
                </FlipCard>
                <FlipCard classes="basis-1/3 h-[52vh]" onClick={() => props.setActiveView('introduction')}>
                    <Card classes="py-[3%] px-[8%]">
                        <Header fontSize="text-xxl">C</Header>
                        <p className="text-center text-md text-primary mt-[15%] mb-[5%]">FELMÉRÉS</p>
                        <p className="text-center text-base text-secondary">Jelezd vissza nekünk, hogy miben lehetünk a
                            segítségedre.</p>
                        <img src="/assets/circles.png" alt="" className="w-2/6 mx-auto my-[10%]"/>
                    </Card>
                </FlipCard>
            </div>
            <div className="w-[35%] mx-auto my-[3%]">
                <TouchIcon/>
            </div>
        </div>
    );
}

export default Games;
