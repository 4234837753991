import React from 'react';
import {resultTimerMillisecond} from "../Config/QuizConfig";

const QuizResult = (props) => {

    const nextQuestionHandler = () => {
        if (props.questionCount < 5) {
            props.setQuizNumber(props.quizNumber + 1);
            props.setCountedGoodAnswers(props.countedGoodAnswers + 1);
            props.setQuestionCount(props.questionCount + 1);
            props.setWasAnswered(false);
            props.setWasTimeout(false);
        } else
            props.setActiveView('exit');
    }

    const timeoutHandler = () => {
        setTimeout(() => {
            nextQuestionHandler();
        }, resultTimerMillisecond);
    }

    return (
        <div className="flex flex-col items-center text-primary font-bold">
            <p className="mb-[8%]">{props.resultText}</p>
            <p className="mb-[8%] text-center">{props.activeQuiz.resultMessage}</p>
            {props.wasCorrectAnswer ?
                <img src="assets/QuizGame/goodAnswerSmiley.png" alt="" className="max-w-[180px] mb-[8%]"/>
                :
                <img src="assets/QuizGame/wrongAnswerSmiley.png" alt="" className="max-w-[180px] mb-[8%]"/>
            }
            {timeoutHandler()}
        </div>
    );
};

export default QuizResult;
