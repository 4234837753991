import React from 'react';
import classes from "./Header.module.scss";

const Header = (props) => {
    return (
        <h1 className={classes['header'] + ' text-center font-bold text-brand uppercase ' + props.fontSize +" "+props.classes }>
            {props.children}
        </h1>
    );
};

export default Header;