import React from 'react';
import Header from "../../Components/Header/Header";
import Card from "../../Components/Card/Card";
import Circles from "../../Components/Shapes/Circles";
import Triangles from "../../Components/Shapes/Triangles";
import HorizontalLines from "../../Components/Shapes/HorizontalLines";
import TouchIcon from "../../Components/TouchIcon/TouchIcon";

const Exit = (props) => {

    return (
        <div className="flex flex-col lg:flex-row min-h-screen">
            <div className="lg:basis-1/2 py-[8%] px-[10%]">
                <Card classes="py-[3%] px-[8%]">
                    <Header fontSize="text-xxl">VÉGE</Header>
                    <p className="text-center text-md text-primary mt-[8%] mb-[4%] font-custom-bold">
                        KÖSZÖNJÜK, HOGY VELÜNK JÁTSZOTTÁL!
                    </p>
                    <p className="text-center text-base text-secondary mb-[4%]">
                        Ha van kedved hozzá, próbáld ki a játék többi részét is! Tartsd a START feliratú kártyát az NFC
                        olvasó terminálhoz!
                    </p>
                    <p className="text-center text-base text-brand mb-[4%]">
                        Jacsomedia Digitális Ügynökség nagy tapasztalattal rendelkezik a kiállítási standaktivitások
                        fejlesztése terén.
                    </p>
                    <p className="text-center text-base text-brand mb-[4%]">
                        VÁLASSZ MINKET!
                    </p>
                    <TouchIcon setActiveView={props.setActiveView}/>
                    <Circles top="-8%" right="-9%"/>
                    <Triangles type="triangles" bottom="-10%" right="-12%"/>
                    <HorizontalLines type="h-lines" top="4%" left="-10%" zIndex="-1"/>
                </Card>

            </div>
            <div className="basis-1/2 bg-cover relative" style={{backgroundImage: "url('/assets/bg-jm.jpg')"}}>
                <img src="/assets/HandPlusCard.png" alt="" className="absolute -left-24 top-0 w-full"/>
            </div>
        </div>
    );
}

export default Exit;
