import React from 'react';
import Header from "../../Components/Header/Header";
import Card from "../../Components/Card/Card";
import Circles from "../../Components/Shapes/Circles";
import Triangles from "../../Components/Shapes/Triangles";
import HorizontalLines from "../../Components/Shapes/HorizontalLines";
import TouchIcon from "../../Components/TouchIcon/TouchIcon";

const Start = (props) => {


    return (
        <div className="flex flex-col lg:flex-row min-h-screen">
            <div className=" flex items-center min-h-screen lg:basis-1/2 py-[8%] px-[10%]">
                <Card classes="flex items-center self-center 2xl:block py-[3%] px-[8%]">
                    <div
                        className="2xl:hidden text-[#ffffff] flex w-full items-center self-center text-center font-bold text-[40px]">
                        A játék full HD képernyőre készült, a megfelelő játékélmény érdekében használj teljes képernyős
                        nézetet!
                    </div>
                    <div className="hidden 2xl:block">
                        <Header fontSize="text-xxl">START</Header>
                        <p className="text-center text-md text-primary mt-[8%] mb-[4%] font-custom-bold">
                            ÜDVÖZLÜNK NFC ALAPÚ STANDAKTIVITÁSUNKBAN!
                        </p>
                        <p className="text-center text-base text-secondary mb-[4%]">
                            Az alkalmazásban a mellékelt NFC kártyák segítségével tudsz majd navigálni, illetve
                            megválaszolni a játék során feltett kérdéseket.
                        </p>
                        <p className="text-center text-base text-secondary mb-[4%]">
                            Tartsd a START feliratú kártyát az NFC olvasó terminálhoz, és kezdődhet is a kvízjáték!
                        </p>

                        <TouchIcon setActiveView={props.setActiveView}/>
                        <p className="text-center text-base text-secondary mb-[4%]">
                            Kellemes időtöltést kívánunk!
                        </p>
                    </div>


                    <Circles top="-8%" right="-9%"/>
                    <Triangles type="triangles" bottom="-10%" right="-12%"/>
                    <HorizontalLines type="h-lines" top="4%" left="-10%" zIndex="-1"/>


                </Card>

            </div>
            <div className="basis-1/2 bg-cover relative hidden lg:block"
                 style={{backgroundImage: "url('/assets/bg-jm.jpg')"}}>
                <img src="/assets/HandPlusCard.png" alt="" className="absolute -left-24 top-0 w-full"/>
            </div>
        </div>
    );
}

export default Start;
