import React from 'react';
import "./AnswerItem.scss";
import classes from "../../../Styles/Animation.module.scss";

const AnswerItem = (props) => {


    const answerHandler = (answer) => {
        props.setWasAnswered(true);
        if (answer === props.activeQuiz.correctAnswerId) {
            props.audioGood.play();
            props.setResultText("Gratulálunk, ez a helyes válasz!");
            props.setWasCorrectAnswer(true);
            props.setCorrectAnswerCounter(props.correctAnswerCounter + 1);
        } else {
            props.audioWrong.play();
            props.setResultText("Sajnos ez a válasz helytelen.");
            props.setWasCorrectAnswer(false);
        }
    }

    return (
        props.activeAnswers.map((ans, index) => {
            return (
                <div key={ans.id} onClick={() => {
                    answerHandler(ans.id)
                }} className={`py-[2%] flex items-center answer-item`}>
                    <p className="text-md">
                        {ans.answer}
                    </p>
                </div>
            )
        })
    );
};

export default AnswerItem;
