import React from 'react';
import styles from "./HorizontalLines.module.scss";

const Shape = (props) => {
   return (
        <img src="/assets/h-lines.png" className={styles["animate"] + " absolute"} style={{
            width: "5.88vw",
            top: props.top,
            left: props.left,
            right: props.right,
            bottom: props.bottom,
            zIndex: props.zIndex
        }}/>
    );
};

export default Shape;