import React from 'react';
import Card from "../../../../Components/Card/Card";
import Header from "../../../../Components/Header/Header";
import Cover from "../Components/Cover";
import FlipCard from "../../../../Components/FlipCard/FlipCard";

const IntroductionGame = (props) => {


    return (
        <div className="flex gap-[3%] justify-center items-center w-full">
            <div className="basis-1/2 h-full">
                <FlipCard classes="h-[85vh]">
                    <Card classes="py-[2%] px-[8%]">
                        <Header>JACSOMÉDIA DIGITÁLIS ÜGYNÖKSÉG</Header>
                        <p className={"text-md text-primary mb-[5%] mt-[10%] font-bold"}>Az alábbi digitális
                            megoldásaink
                            közül
                            melyiket érdekel leginkább?</p>
                        <ul className="text-primary">
                            <li className={"py-1 flex items-center"}>
                                <span
                                    onClick={() => props.setActiveScreen('branding')}
                                    className="border border-solid text-[#898EA7] mr-5 bg-[#363948] rounded-full w-[10%] aspect-square flex justify-center items-center">
                                    A
                                </span>
                                Kreatív tervezés (UI/UX, branding)
                            </li>
                            <li className={"py-3 flex items-center"}>
                                <span
                                    onClick={() => props.setActiveScreen('development')}
                                    className="border border-solid text-[#898EA7] mr-5 bg-[#363948] rounded-full w-[10%] aspect-square flex justify-center items-center">
                                    B
                                </span>
                                Szoftverfejlesztés (web, app,<br/>kiállítási játék, edetailer, edm)
                            </li>
                            <li className={"py-3 py-2 flex items-center"}>
                                <span
                                    onClick={() => props.setActiveScreen('onlineMarketing')}
                                    className="border border-solid text-[#898EA7] mr-5 bg-[#363948] rounded-full w-[10%] aspect-square flex justify-center items-center">
                                    C
                                </span>
                                Online Marketing
                            </li>
                            <img onClick={() => props.setActiveView('games')} src="/assets/touch-icon.png" alt=""
                                 className="max-w-[15%] absolute bottom-[5%]"/>
                        </ul>
                    </Card>
                </FlipCard>
            </div>
            {<Cover animate="true"/>}
        </div>

    );
};

export default IntroductionGame;
