import React from 'react';
import classes from './Triangles.module.scss'

const Triangles = (props) => {
    return (
        <div className="absolute" style={{
            width: "12vw",
            top: props.top,
            left: props.left,
            right: props.right,
            bottom: props.bottom,
            zIndex: props.zIndex
        }}>
            <div className="relative">
                <svg xmlns="http://www.w3.org/2000/svg" width="250" height="435" viewBox="0 0 503 435"
                     className={classes['animate-big'] + " top-0"}>
                    <g id="Polygon_1" data-name="Polygon 1" fill="none">
                        <path d="M251.5,0,503,435H0Z" stroke="none"/>
                        <path
                            d="M 251.5 11.9874267578125 L 10.39962768554688 429 L 492.6003723144531 429 L 251.5 11.9874267578125 M 251.5 0 L 503 435 L 0 435 L 251.5 0 Z"
                            stroke="none" fill="#fecd00"/>
                    </g>
                </svg>
                <svg xmlns="http://www.w3.org/2000/svg" width="150" height="255" viewBox="0 0 294 255"
                     className={classes['animate-small'] + " absolute top-1/3 -right-[70px]"}>
                    <g id="Polygon_2" data-name="Polygon 2" transform="translate(294 255) rotate(180)" fill="none">
                        <path d="M147,0,294,255H0Z" stroke="none"/>
                        <path
                            d="M 147.0000152587891 12.01374816894531 L 10.3843994140625 249.0000152587891 L 283.6156311035156 249.0000152587891 L 147.0000152587891 12.01374816894531 M 147.0000152587891 1.52587890625e-05 L 294 255.0000152587891 L 3.0517578125e-05 255.0000152587891 L 147.0000152587891 1.52587890625e-05 Z"
                            stroke="none" fill="#fecd00"/>
                    </g>
                </svg>
            </div>


        </div>

    );
};

export default Triangles;
