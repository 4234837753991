import React, {useEffect, useState} from 'react';
import classes from './Timer.module.scss'

const Timer = (props) => {
    const [timer, setTimer] = useState(props.time);

    useEffect(() => {
        if (timer === 0) {
            props.onEnd();
        }
    }, [timer])

    useEffect(() => {
        setInterval(() => {
            setTimer(old => {
                if (old > 0) return --old;
                return old;
            })
        }, 1000)
    }, [])

    /*useEffect(() => {
        setTimer(props.time);
    }, [props.start])*/

    return (
        <div className="flex flex-row w-full items-center justify-center gap-2">
            <img src="/assets/touch-icon.png" alt="" className="w-1/6"/>
            <div className="rounded-full text-base p-[0.5%] my-[5%] w-4/6 bg-dark-gray">
                <div className="bg-brand min-h-[0.5rem] rounded-full" style={{width: `calc(98% * (${timer / props.time}))`}}></div>
            </div>
            <div className="text-primary text-base w-1/6">
                {timer} mp
            </div>
        </div>
    )
}

export default Timer;