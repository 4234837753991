import React from 'react';
import classes from "./Card.module.scss";

const Card = (props) => {

    return (
        <div className={classes["card"] + " bg-light-gray rounded-[3%] relative h-full " + props.classes}
        >
            {props.children}
        </div>
    );
};

export default Card;
