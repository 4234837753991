import React from 'react';
import Cover from "../Components/Cover";
import Text from "../Components/Text";

const Development = (props) => {

    return (
        <div className="flex gap-[5%] justify-center items-center w-full">
            <Text setActiveScreen={props.setActiveScreen} activeScreen={props.activeScreen}/>
            <Cover/>
        </div>
    );
};

export default Development;
