import React, {useState, useEffect} from 'react';
import Card from "../../../../Components/Card/Card";
import Timer from "../../../../Components/Timer/Timer";
import {answers, maxQuizNumber, gameTimerSeconds} from "../Config/QuizConfig";
import AnswerItem from "../Components/AnswerItem";
import Counter from "../Components/Counter";
import QuizResult from "../Components/QuizResult";
import Circles from "../../../../Components/Shapes/Circles";
import VerticalLines from "../../../../Components/Shapes/VerticalLines";
import HorizontalLines from "../../../../Components/Shapes/HorizontalLines";
import Triangles from "../../../../Components/Shapes/Triangles";
import FlipCard from "../../../../Components/FlipCard/FlipCard";
import styles from "./QuizGame.module.scss";

const QuizGame = (props) => {

    let [quizNumber, setQuizNumber] = useState(1);
    const [quizQuestions] = useState(props.quizQuestions);
    const [activeQuizAnswers, setActiveQuizAnswers] = useState([]);
    const [activeQuiz, setActiveQuiz] = useState({});
    let [countedGoodAnswers, setCountedGoodAnswers] = useState(0);
    const [wasCorrectAnswer, setWasCorrectAnswer] = useState(false);
    const [wasTimeout, setWasTimeout] = useState(false);
    const [wasAnswered, setWasAnswered] = useState(false);
    const [resultText, setResultText] = useState("");

    let audioGood = new Audio('assets/audio/good_answer.wav');
    let audioWrong = new Audio('assets/audio/wrong_answer.wav');

    useEffect(() => {
        if (quizNumber - 1 === quizQuestions.length)
            props.setActiveView('exit');
        else {
            const array = [...answers].filter((answer) => quizQuestions[quizNumber - 1].answersId.includes(answer.id));
            setActiveQuiz(quizQuestions[quizNumber - 1]);
            setActiveQuizAnswers(array);
        }
    }, [quizNumber]);


    const getQuizQuestions = () => {
        return (
            <div>
                {activeQuiz.question}
            </div>
        )
    };

    const getQuizImage = () => {
        return (
            <div className={"basis-1/2 flex justify-center items-center"}>
                <div className="max-w-[80%]">
                    {wasAnswered || wasTimeout ?
                        <img src={activeQuiz.answerImage} alt="" className={"grayscale"}/> :
                        <img src={activeQuiz.image} alt="" className={"grayscale " + styles["fadeIn"]}/>}
                    <Circles right="5%" bottom="10%"/>
                    <HorizontalLines bottom="20%" right="44%" zIndex="-1"/>
                    <VerticalLines top="4%" right="8%"/>
                    <Triangles top="1%" right="39%"/>
                </div>
            </div>
        )
    }

    return (
        <div className="flex flex-row justify-center items-center min-h-screen">
            <div className={"basis-1/2 flex justify-center items-center"}>
                <div className="w-[80%]">
                    {wasAnswered || wasTimeout ?
                        <Card classes="py-[1%] px-[8%] h-[65vh]">
                            <Counter questionCount={props.questionCount} maxQuizNumber={maxQuizNumber}
                                     wasAnswered={wasAnswered}/>
                            <QuizResult resultText={resultText} wasCorrectAnswer={wasCorrectAnswer}
                                        activeQuiz={activeQuiz} setQuizNumber={setQuizNumber}
                                        quizNumber={quizNumber} setWasAnswered={setWasAnswered}
                                        setQuestionCount={props.setQuestionCount}
                                        questionCount={props.questionCount}
                                        setActiveView={props.setActiveView}
                                        setCountedGoodAnswers={setCountedGoodAnswers}
                                        countedGoodAnswers={countedGoodAnswers} setWasTimeout={setWasTimeout}/>
                        </Card>
                        : <FlipCard classes="h-[65vh]">
                            <Card classes="py-[1%] px-[8%]">
                                <Counter questionCount={props.questionCount} maxQuizNumber={maxQuizNumber}
                                         wasAnswered={wasAnswered}/>
                                <div>
                                    <p className={"text-md text-primary text-md mb-[5%] font-bold"}>{getQuizQuestions()}</p>
                                    <div className="text-primary mb-[5%]">
                                        <AnswerItem activeAnswers={activeQuizAnswers}
                                                    activeQuiz={activeQuiz}
                                                    setWasAnswered={setWasAnswered} setResultText={setResultText}
                                                    setWasCorrectAnswer={setWasCorrectAnswer}
                                                    correctAnswerCounter={props.correctAnswerCounter}
                                                    setCorrectAnswerCounter={props.setCorrectAnswerCounter}
                                                    audioGood={audioGood}
                                                    audioWrong={audioWrong}

                                                    setActiveView={props.setActiveView}
                                        />
                                    </div>
                                    <div className="my-[2%]">
                                        <Timer time={gameTimerSeconds} onEnd={() => {
                                            setWasCorrectAnswer(false);
                                            setWasTimeout(true);
                                            setResultText('Sajnos lejárt az idő.')
                                        }}/>
                                    </div>
                                </div>
                            </Card>
                        </FlipCard>}
                </div>
            </div>
            {getQuizImage()}
        </div>
    );
};

export default QuizGame;
